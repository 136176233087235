.hamburger-menu {
  display: none;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 1001;
  cursor: pointer;
  padding: 10px;
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px 0;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Hamburger animation when active */
.hamburger-menu.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.active span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.active span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(53, 59, 68, 0.98);
  z-index: 999;
  padding-top: 70px;
  overflow-y: auto;
}

.mobile-nav.active {
  display: block;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 70px);
}

.menu-items li {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.menu-items li a {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  padding: 15px 0;
  width: 100%;
}

.menu-items li a:hover {
  color: #DCDA2D;
  text-decoration: none;
}

.desktop-nav {
  display: block;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .hamburger-menu {
    top: 12px;
    right: 15px;
    background-color: transparent;
  }

  .mobile-nav {
    display: none;  /* Hide by default on mobile */
  }

  .mobile-nav.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  #navigator .nav-items-list {
    display: none;
  }
}
