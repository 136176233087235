/* ProductPage.css */
.product-page-container {
    background-color: #21478B; /* Dark blue background for the entire page */
    padding: 20px;
    font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
    color: #f9f9f9;
    min-height: calc(100vh - 90px); /* Reduced from 110px */
    margin-top: 90px; /* Reduced from 110px to match header + nav exact height */
    width: 100%;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Adjust minmax as needed */
    gap: 20px;
    max-width: 1400px;
    margin: 0 auto; /* Center the grid */
}

.product-card {
    background-color: #21478B; /* Same blue as container */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow */
}

.product-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
    background-color: #21478B; /* Match container background */
}

.product-image {
    width: 100%;
    height: auto;
    max-height: 300px; /* Set a maximum height */
    object-fit: cover;
    margin-bottom: 20px; /* Space between image and details */
}

.product-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.company-logo {
    max-width: 200px;
    height: auto;
    margin-bottom: 10px;
}

.product-details h3 {
    margin-bottom: 5px;
    color: #F3C35B; /* Yellowish title */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .product-grid {
        grid-template-columns: 1fr; /* Single column on smaller screens */
    }
}

/* Product-specific styles */
.block-content {
    padding: 15px;
    margin-bottom: 20px;
    background-color: #3159A3;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.logo-box {
    flex-shrink: 0;
    width: 220px;
    height: 140px;
    padding: 10px;
    background-color: #fff;
    border: 3px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logo-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-details {
    flex: 1;
}

.inner-title {
    color: #F3C35B;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Tablet Responsive (768px to 1024px) */
@media screen and (max-width: 1024px) {
    .block-content {
        padding: 15px;
        margin: 0 10px 20px 10px;
    }

    .logo-box {
        width: 200px;
        height: 130px;
    }

    .product-details {
        flex: 1;
        padding-right: 10px;
    }

    .inner-title {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .product-details p {
        font-size: 13px;
        line-height: 1.4;
    }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
    .block-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .logo-box {
        width: 180px;
        height: 120px;
    }

    .product-details {
        width: 100%;
    }
}

/* Mobile Responsive */
@media screen and (max-width: 480px) {
    .block-content {
        padding: 10px;
    }

    .logo-box {
        width: 150px;
        height: 100px;
    }

    .inner-title {
        font-size: 14px;
    }

    .product-details p {
        font-size: 13px;
    }
}

.company-images {
    margin-bottom: 20px;
    border: 3px solid #ccc;
    background: #fff;
    padding: 5px;
    width: 250px;  /* Fixed width for consistency */
    height: 200px; /* Fixed height for consistency */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.company-images img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will cover the area without stretching */
    border: none;
}

/* Tablet Responsive */
@media screen and (max-width: 1024px) {
    .company-images {
        width: 200px;
        height: 160px;
    }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
    .company-images {
        width: 180px;
        height: 140px;
    }
}

/* Small Mobile */
@media screen and (max-width: 480px) {
    .company-images {
        width: 150px;
        height: 120px;
    }
}