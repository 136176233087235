/* Reset the container styles */
.product-page-container {
  background-color: #21478B;
  padding: 20px;
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  color: #f9f9f9;
  min-height: calc(100vh - 110px); /* Account for header and nav */
  margin-top: 110px; /* Height of header + nav */
  width: 100%;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.product-card {
  background-color: #21478B; /* Same blue as container */
  border-radius: 8px;
  overflow: hidden;
}

.product-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}

.product-page-container.clients-page-container .content-module {
  display: flex;
  gap: 20px;
  padding: 0; /* Remove padding since container has it */
}

/* Remove margin-top from content-module */
#navigator + .product-page-container.clients-page-container .content-module {
  margin-top: 0;
}

.product-page-container.clients-page-container .product-content-wrapper {
  display: flex;
  gap: 30px;
  width: 100%;
}

.product-page-container.clients-page-container .left-side-block {
  flex: 0 0 250px;
  margin-right: 20px;
}

.product-page-container.clients-page-container .left-main-block {
  flex: 1;
}

.product-page-container.clients-page-container .company-images {
  margin-bottom: 20px;
  border: 3px solid #ccc;
  background: #fff;
  padding: 5px;
  width: 250px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.product-page-container.clients-page-container .company-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

.product-page-container.clients-page-container .page-title {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
}

/* Clients table styling */
.product-page-container.clients-page-container .clients-table {
  width: 100%;
  border-collapse: collapse;
}

.product-page-container.clients-page-container .c-logo {
  width: 200px;
  padding: 0;
}

.product-page-container.clients-page-container .logo-box {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin: 10px;
}

.product-page-container.clients-page-container .logo-box img {
  max-width: 100%;
  height: auto;
  display: block;
}

.product-page-container.clients-page-container .c-details {
  padding: 20px;
}

.product-page-container.clients-page-container .inner-title {
  font-weight: bold;
  font-size: 1.1em;
  color: #FFA500;
  margin-bottom: 8px;
  display: block;
}

/* Media query for tablets */
@media screen and (max-width: 1024px) {
  .product-page-container.clients-page-container .left-side-block {
    flex: 0 0 200px;
    margin-right: 15px;
  }

  .product-page-container.clients-page-container .c-logo {
    width: 120px;
  }

  .product-page-container.clients-page-container .company-images {
    width: 200px;
    height: 160px;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .product-page-container.clients-page-container .content-module {
    padding: 15px;
  }

  .product-page-container.clients-page-container .product-content-wrapper {
    flex-direction: column;
  }

  .product-page-container.clients-page-container .left-side-block {
    flex: none;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .product-page-container.clients-page-container .left-main-block {
    width: 100%;
  }

  /* Stack table cells on mobile */
  .product-page-container.clients-page-container .clients-table tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .product-page-container.clients-page-container .c-logo {
    width: 100%;
    text-align: center;
  }

  .product-page-container.clients-page-container .logo-box {
    max-width: 200px;
    margin: 0 auto;
  }

  .product-page-container.clients-page-container .c-details {
    text-align: center;
    padding: 15px 10px;
  }

  .product-page-container.clients-page-container .company-images {
    width: 180px;
    height: 140px;
  }
}

/* Media query for very small devices */
@media screen and (max-width: 480px) {
  .product-page-container.clients-page-container .content-module {
    padding: 10px;
  }

  .product-page-container.clients-page-container .inner-title {
    font-size: 1em;
  }

  .product-page-container.clients-page-container .c-details {
    font-size: 0.9em;
  }

  .product-page-container.clients-page-container .company-images {
    width: 150px;
    height: 120px;
  }
}

.block-content {
    padding: 15px;
    margin-bottom: 20px;
    background-color: #3159A3;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.logo-box {
    flex-shrink: 0;
    width: 220px;
    height: 140px;
    padding: 10px;
    background-color: #fff;
    border: 3px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logo-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-details {
    flex: 1;
}

.inner-title {
    color: #F3C35B;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Tablet Responsive (768px to 1024px) */
@media screen and (max-width: 1024px) {
    .block-content {
        padding: 15px;
        margin: 0 10px 20px 10px;
    }

    .logo-box {
        width: 200px;
        height: 130px;
    }

    .product-details {
        flex: 1;
        padding-right: 10px;
    }

    .inner-title {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .product-details p {
        font-size: 13px;
        line-height: 1.4;
    }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
    .block-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .logo-box {
        width: 180px;
        height: 120px;
    }

    .product-details {
        width: 100%;
    }
}

/* Mobile Responsive */
@media screen and (max-width: 480px) {
    .block-content {
        padding: 10px;
    }

    .logo-box {
        width: 150px;
        height: 100px;
    }

    .inner-title {
        font-size: 14px;
    }

    .product-details p {
        font-size: 13px;
    }
}
